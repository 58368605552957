import React, { useState, useEffect } from 'react'
import ban1 from '../../assets/BAN1.png'
import ban2 from '../../assets/BAN2.png'
import ban3 from '../../assets/BAN3.png'
import ban4 from '../../assets/BAN4.png'

const images = [ban1, ban2, ban3, ban4]

export default function BannerSlider() {
  const [currentSlide, setCurrentSlide] = useState(0)

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % images.length)
    }, 20000)

    return () => clearInterval(timer)
  }, [])

  const goToSlide = (index) => {
    setCurrentSlide(index)
  }

  return (
    <div className="relative w-full overflow-hidden">
      {/* Slides */}
      <div
        className="flex transition-transform duration-500 ease-out"
        style={{ transform: `translateX(-${currentSlide * 100}%)` }}
      >
        {images.map((banner) => (
          <div key={banner.id} className="min-w-full h-full relative">
            <img
              src={banner}
              alt="banner"
              className="w-full h-auto object-cover"
            />
            <div className="absolute inset-0 bg-black bg-opacity-20 flex flex-col items-center justify-center text-white">
              {/* <h2 className="text-5xl font-bold mb-4">title</h2>
              <p className="text-xl">subtitle</p> */}
            </div>
          </div>
        ))}
      </div>

      {/* Dots Navigation */}
      <div className="absolute top-20 left-1/2 -translate-x-1/2 flex gap-2">
        {images.map((_, index) => (
          <button
            key={index}
            onClick={() => goToSlide(index)}
            className={`w-2 h-2 rounded-full transition-all ${
              currentSlide === index ? 'bg-white' : 'bg-white/50'
            }`}
            aria-label={`Go to slide ${index + 1}`}
          />
        ))}
      </div>
    </div>
  )
}
